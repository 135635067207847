<template>
  <div class="c-page-footer-wrap">
    <div class="footer">
      <div class="footer-main">
        <div class="order-block-bottom">
          <div class="block-bottom-content">
            <div class="block-icon"><i class="iconfont icon-dianhuaji"></i></div>
            <div class="block-dividing-line"></div>
            <div class="order-contact" style="float:left">
              <p class="order-contact-mode">Online Time</p>
              <p class="order-contact-data">客服在线  9:00~18:00</p>
            </div>
          </div>
          <div class="block-bottom-content">
            <div class="block-icon"><i class="iconfont icon-xinfeng"></i></div>
            <div class="block-dividing-line"></div>
            <div class="order-contact" style="float:left">
              <p class="order-contact-mode">Email Us</p>
              <p class="order-contact-data">postmaster@luckypaths.com</p>
            </div>
          </div>
          <div class="block-bottom-content">
            <div class="block-icon"><i class="iconfont icon-xiugai"></i></div>
            <div class="block-dividing-line"></div>
            <div class="order-contact" style="float:left;color: #d2d2d2;line-height: 44px;">
              <!-- <p class="order-contact-mode">Online QQ</p>
              <a href="http://wpa.qq.com/msgrd?v=3&uin=2851140632&site=qq&amp;menu=yes" target="_blank"><p class="order-contact-data">在线客服QQ：2851140632</p></a> -->
              <p><a href="/#/Ask" style="color: #d2d2d2;cursor: pointer" title="">免费获得选校报告</a></p>
            </div>
          </div>
        </div>

        <div class="website-info">
          <div class="logo-img">
            <div>
              <a  title="">
                <img src="../assets/logo1.png" width="186px"  title="">
              </a>
            </div>
            <div style="color: #A1A1A1;margin-left: 50px;margin-top: 30px">
              <div style="font-size: 12px">全国电话</div>
              <div style="font-size: 17px">400-8609-158</div>
              <div style="font-size: 12px;margin-top: 10px">香港电话</div>
              <div style="font-size: 17px">852-62822226</div>
            </div>
          </div>
          <div>
            <div>
              <div style="width: 350px;height: 190px;"><img style="margin-top:35px;margin-left:20px;width: 310px;height: 140px;" src="http://www.luckypaths.com/image/img-mini.png"></div>
              <div style="font-size: 18px;color: #CCCCCC ;font-weight: bold;text-align: center;">最新资讯 全球名校升学干货一手掌握</div>
            </div>
            <!--<div>-->
              <!--<div style="color: #A1A1A1;margin-top: 20px;text-align: center">您还可以在这些地方找到我们</div>-->
              <!--<div style="text-align: center;margin-top: 10px">-->
                <!--<img style="width: 32px;height: 32px;margin-right: 14px" src="../assets/imgs/img-zhihu.png">-->
                <!--<img style="width: 32px;height: 32px;margin-right: 14px" src="../assets/imgs/img-xhs.png">-->
                <!--<img style="width: 32px;height: 32px;margin-right: 14px" src="../assets/imgs/img-bilibili.png">-->
                <!--<img style="width: 32px;height: 32px;margin-right: 14px" src="../assets/imgs/img-shipin.png">-->
                <!--<img style="width: 32px;height: 32px;margin-right: 14px" src="../assets/imgs/img-tout.png">-->
                <!--<img style="width: 32px;height: 32px;margin-right: 14px" src="../assets/imgs/img-dy.png">-->
              <!--</div>-->
            <!--</div>-->
          </div>

        </div>
        <div class="website-link" style="width: 240px">
          <h3 class="website-link-title">服务项目</h3>
          <!--<a href="javascript:void (0)"   class="website-link-item-left" title="">香港留学</a>-->
          <div class="website-link-item-left-my" >
            <div  @click="open(1)"  class="website-link-item-right-my2"   title="">职业规划</div>
            <div  @click="open(2)"  class="website-link-item-right-my2" >就读体验</div>
            <div  @click="open(3)"  class="website-link-item-right-my2"  title="">文书撰写</div>
            <div  @click="open(4)" class="website-link-item-right-my2"  title="">网申助手</div>
            <div  @click="open(5)" class="website-link-item-right-my2"  title="">面试辅导</div>
            <div  @click="open(6)" class="website-link-item-right-my2"  title="">签证指导</div>
          </div>
          <div class="website-link-item-right-my">

            <div  @click="open(7)" class="website-link-item-right-my2"   title="">选课指导</div>
            <div  @click="open(8)" class="website-link-item-right-my2"   title="">课程辅导</div>
            <div  @click="open(9)" class="website-link-item-right-my2"   title="">租房协助</div>
            <div  @click="open(10)" class="website-link-item-right-my2"   title="">实习内推</div>
            <div  @click="open(11)" class="website-link-item-right-my2"   title="">全职内推</div>

          </div>





        </div>
        <div class="website-link" style="margin-left: 100px">
          <h3 class="website-link-title">资源</h3>
          <!-- <a href="/cases/" target="_blank" class="website-link-item-left">录取分析</a> -->
          <a href="#/MovingUp"  @click="$router.push({ path: 'MovingUp' })" class="website-link-item-left" title="">名企实习</a>

          <a href="#/OverSeas?nation=英国&education=本科"  class="website-link-item-left" title="">英国高效</a>
          <a href="#/OverSeas?nation=美国&education=本科"  class="website-link-item-left" title="">美国高校</a>
          <a href="#/OverSeas?nation=中国澳门&education=本科"  class="website-link-item-left" title="">澳门高校</a>
          <a href="#/OverSeas?nation=中外合办&education=本科"  class="website-link-item-left" title="">中外合办</a>
          <a href="#/OverSeas?nation=中国香港&education=本科"  class="website-link-item-left" title="">香港高校</a>
          <a @click="openCode2"  class="website-link-item-left" title="">香港中小学</a>
          <a href="#/MovingUp"  class="website-link-item-left" title="">教授科研</a>
          <!-- <a href="/course.html" target="_blank" class="website-link-item-left">精品课程</a> -->
          <!--<a href="/evaluate/" target="_blank" class="website-link-item-right">导师口碑</a>-->
          <!--<a href="javascript:void (0)" @click="$router.push({ path: 'about' })" class="website-link-item-left" title="">问答社区</a>-->
          <!--<a href="javascript:void (0)" @click="$router.push({ path: 'about' })" class="website-link-item-right" title="">文书样本</a>-->

        </div>
        <div class="website-link" style="width: 100px;margin-left: 50px">
          <h3 class="website-link-title">支持</h3>
          <a href="https://www.wjx.cn/vm/mTPNoWw.aspx#" class="website-link-item-left2" style="min-width: 90px" title="">成为导师</a>
          <a href="https://www.wjx.cn/vm/tbRjkHM.aspx#" class="website-link-item-left2" style="min-width: 90px" title="">加入我们</a>
          <a href="javascript:void (0)" @click="$router.push({ path: 'About' })" class="website-link-item-left2" style="min-width: 90px" title="">了解我们</a>
          <!--<a href="javascript:void (0)" @click="openCode2" class="website-link-item-left2" style="min-width: 90px" title="">网站地图</a>-->
          <a href="javascript:void (0)" class="website-link-item-left2" @click="openCode2" style="min-width: 90px" title="">投诉建议</a>
        </div>
      </div>
      <div>
        <div style="font-size: 18px;font-weight: bold;color: #CCCCCC;text-align: center;margin-top: 10px">合作伙伴</div>
        <div class="friend">
          <img src="http://www.luckypaths.com/image/img-hezuo1.png">
          <img src="http://www.luckypaths.com/image/img-hezuo2.png">
          <img src="http://www.luckypaths.com/image/img-hezuo3.png">
          <img src="http://www.luckypaths.com/image/img-hezuo4.png">
          <img src="http://www.luckypaths.com/image/img-hezuo5.png">
          <img src="http://www.luckypaths.com/image/img-hezuo6.png">
          <img src="http://www.luckypaths.com/image/img-hezuo7.png">
          <img src="http://www.luckypaths.com/image/img-hezuo8.png">
          <img src="http://www.luckypaths.com/image/img-hezuo9.png">
          <img src="http://www.luckypaths.com/image/img-hezuo10.png">
        </div>
      </div>
      <div style="margin: 30px  0 20px 0; color: #888888;text-align: center;border-top:1px  solid  rgba(153, 153, 153, 0.1);padding-top: 20px">
        北京陆取国际科技有限公司 经营许可证编号：京ICP备2022003423号-3<br>
        地址：北京市三里屯SOHO办公B座1909室；香港尖東麼地道77號華懋廣場1016室；南昌市红谷滩区绿地中心双子塔A2楼2103A室
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'PageFooter',
    data () {
      return {
        imgSrc: require('../assets/imgs/code.jpg'),
        imgSrc2: require('../assets/imgs/miniprogram.png')
      }
    },
    methods: {
      openCode () {
        /* eslint-disable */
        layer.open({
          offset: '200px',
          title: '扫一扫添加好友',
          btn: [],
          content: `<img src="${this.imgSrc}" width="320" />` //这里content是一个普通的String
        });
      },
      openCode2 () {
        /* eslint-disable */
        layer.open({
          offset: '200px',
          title: ' ',
          btn: [],
          content: `<img src="http://www.luckypaths.com/image/assets/pckefu.jpg" width="820" />` //这里content是一个普通的String
        });
      },
        open(type){
          if(type==1){
              layer.open({
                  offset: '200px',
                  title: ' ',
                  btn: [],
                  content: `<img src="http://www.luckypaths.com/image/assets/全流程保障图_01.jpg" width="820" />` //这里content是一个普通的String
              });
          }else  if(type==2){
              layer.open({
                  offset: '200px',
                  title: ' ',
                  btn: [],
                  content: `<img src="http://www.luckypaths.com/image/assets/全流程保障图-03.jpg" width="820" />` //这里content是一个普通的String
              });
          }
          else  if(type==3){
              layer.open({
                  offset: '200px',
                  title: ' ',
                  btn: [],
                  content: `<img src="http://www.luckypaths.com/image/assets/全流程保障图-04.jpg" width="820" />` //这里content是一个普通的String
              });
          }
          else  if(type==4){
              layer.open({
                  offset: '200px',
                  title: ' ',
                  btn: [],
                  content: `<img src="http://www.luckypaths.com/image/assets/全流程保障图-05.jpg" width="820" />` //这里content是一个普通的String
              });
          }
          else  if(type==5){
              layer.open({
                  offset: '200px',
                  title: ' ',
                  btn: [],
                  content: `<img src="http://www.luckypaths.com/image/assets/全流程保障图-06.jpg" width="820" />` //这里content是一个普通的String
              });
          }
          else  if(type==6){
              layer.open({
                  offset: '200px',
                  title: ' ',
                  btn: [],
                  content: `<img src="http://www.luckypaths.com/image/assets/全流程保障图-07.jpg" width="820" />` //这里content是一个普通的String
              });
          }
          else  if(type==7){
              layer.open({
                  offset: '200px',
                  title: ' ',
                  btn: [],
                  content: `<img src="http://www.luckypaths.com/image/assets/全流程保障图-08.jpg" width="820" />` //这里content是一个普通的String
              });
          }
          else  if(type==8){
              layer.open({
                  offset: '200px',
                  title: ' ',
                  btn: [],
                  content: `<img src="http://www.luckypaths.com/image/assets/全流程保障图-09.jpg" width="820" />` //这里content是一个普通的String
              });
          }
          else  if(type==9){
              layer.open({
                  offset: '200px',
                  title: ' ',
                  btn: [],
                  content: `<img src="http://www.luckypaths.com/image/assets/全流程保障图-10.jpg" width="820" />` //这里content是一个普通的String
              });
          }
          else  if(type==10){
              layer.open({
                  offset: '200px',
                  title: ' ',
                  btn: [],
                  content: `<img src="http://www.luckypaths.com/image/assets/全流程保障图-11.jpg" width="820" />` //这里content是一个普通的String
              });
          }
          else  if(type==11){
              layer.open({
                  offset: '200px',
                  title: ' ',
                  btn: [],
                  content: `<img src="http://www.luckypaths.com/image/assets/全流程保障图-12.jpg" width="820" />` //这里content是一个普通的String
              });
          }

        }
    }
  }
</script>

<style lang="less" scoped>
.c-page-footer-wrap {
  .order-block-bottom{width:1200px;height:90px;background-color: #393939;
    margin-bottom: 15px;}
  .logo-img{display: flex}
  .block-bottom-content{width:275px;height:44px;margin:20px 62px;float: left;}
  .block-bottom-content .block-icon{width:40px;height:40px;margin:2px 20px 0 0;float: left;text-align: center;}
  .block-bottom-content .block-icon i{font-size: 40px;color: #d2d2d2;}
  .block-dividing-line{width: 2px;height: 24px;background-color: #d2d2d2;border-radius: 1px;margin:10px 31px 0 0;float: left;}
  .order-contact-mode{font-size: 16px;line-height: 16px;color: #d2d2d2;margin-bottom: 14px;}
  .order-contact-data{font-size: 14px;line-height: 14px;color: #b3b3b3;cursor: pointer;}
  /*.footer{width:100%;height:365px;border-bottom:1px solid rgba(136,136,136,0.4);background-color: #2b2b2b;position: relative;}*/
  .footer{width:100%;border-bottom:1px solid rgba(136,136,136,0.4);background-color: #2b2b2b;position: relative;}
  .footer-main{width:1200px;margin:0 auto;box-sizing: border-box;position: relative;overflow: hidden;padding-bottom: 20px;border-bottom:1px  solid  rgba(153, 153, 153, 0.1) }
  .website-info{width:360px;float: left;border-right:1px  solid  rgba(153, 153, 153, 0.1);margin-right: 80px;padding-right: 50px }
  .footer-main .website-title{font-size: 14px;line-height: 46px;color: #999999;}
  .footer-main .website-tel{font-size: 16px;color: #999999;line-height: 46px;}
  .website-share .website-share-item{width: 38px;height: 38px;background-color: #393939;border-radius: 2px;text-align: center;float: left;margin-right:12px;position: relative;}
  .website-share-item:hover i{color: #1f1f1f;}
  .website-share-item:hover{background-color: rgba(255, 255, 255, 0.8);}
  .website-share-item i{font-size: 18px;color:#9e9e9e;line-height: 38px;}
  .website-link{width:170px;float: left;margin-left: 10px}
  .website-link-title{font-size: 18px;font-weight: bold;line-height: 24px;color: #999999;margin-bottom:10px;}
  .website-link-item-left{min-width:114px;font-size: 14px;line-height: 36px;color: #888888;display: block;float: left;}
  .website-link-item-left-my{
    min-width:114px;font-size: 14px;line-height: 36px;color: #888888;display: block;float: left;
  }
  .website-link-item-right{min-width:114px;font-size: 14px;line-height: 36px;color: #888888;display: block;float: right;}
  .website-link-item-right-my{min-width:114px;font-size: 14px;line-height: 36px;color: #888888;display: block;float: right;}
  .website-link-item-right-my2:hover{color:#e5e5e5;}
  .website-link-item-left2{min-width:114px;font-size: 14px;line-height: 36px;color: #888888;display: block;}
  .website-link-item-left:hover,.website-link-item-right:hover{color:#e5e5e5;}
  .website-link-item-right{width:auto;}
  .bottom-nav{width:100%;background-color: #2b2b2b;}
  .bottom-nav-list{width:1200px;margin:0 auto;padding-top:20px;box-sizing: border-box;overflow: hidden;}
  .bottom-nav-item,.bottom-nav-line{font-size: 14px;line-height: 24px;color: #999999;}
  .bottom-nav-item:hover{color:#e5e5e5;}
  .bottom-nav-line{padding:0 9px;}
  .bottom-nav-list .copyright-text{font-size: 14px;font-style: italic;line-height: 24px;color: #666666;}
  .copyright{width:1200px;margin:0 auto;padding:24px 0;overflow: hidden;}
  .copyright .security{width:350px;float: left;}
  .copyright .security-img1{display: inline-block;width:83px;height:25px;margin-right:16px;}
  .copyright .security-img1 img{width:83px;height:25px;}
  .copyright .security-img2{display: inline-block;width:81px;height:25px;margin-right:16px;}
  .copyright .security-img2 img{width:81px;height:25px;}
  .copyright .security-img3{display: inline-block;width:88px;height:25px;}
  .copyright .security-img3 img{width:88px;height:25px;}
  .copyright .copyright-info{width:500px;float: left;font-size: 14px;line-height: 24px;color: #888888;text-align: center;}
  .copyright .company-address{width:350px;float: left;font-size: 14px;line-height: 24px;color: #888888;text-align: right;}
  .friend{
    display: grid;
    grid-row-gap: 30px;
    grid-template-columns: repeat(auto-fill, 192px);
    grid-column-gap: 60px;
    /*grid-template-columns: repeat(5,25%);*/
    width: 1200px;
    margin: 13px auto 0;
    img{
      width: 192px;
      height: 58px;
    }
  }
  /* 底部 结束 */
}
</style>
